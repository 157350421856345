<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="600px"
        min-width="600px"
        max-width="700px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode === false ? $t('message.addStandardShippingDocument') : $t('message.updateStandardShippingDocument') + ": " + standardShippingDocument }}</span>
                <HelpPopUpV2 help-page-component="AddStandardShippingDocument" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="standardShippingDocumentForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.title') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    :value="Document__title"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Document__title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.typeId') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.integer, ...validationRules.minimumOne]"
                                    :value="Document__doctype_id"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Document__doctype_id = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.fileName') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="Document__fileName"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Document__fileName = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.categories') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-autocomplete
                                    :items='documentCategories'
                                    :item-text="item => $t('message.docTypes.' + item.text)"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    chips
                                    dense
                                    hide-details="auto"
                                    multiple
                                    solo
                                    v-model="Document__categories"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip
                                        class="my-1"
                                        close
                                        @click:close="removeOption(item.value)"
                                    >
                                        {{ $t('message.docTypes.' + item.text) }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.customerAccess') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-checkbox
                                    :label="$t('message.yes')"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="Document__customeraccess"
                                    v-if="$can('update','StandardDocumentBuyerAccess')"
                            />
                            <span v-else>{{ Document__customeraccess === 1 ? $t('message.yes') : $t('message.no') }}</span>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.folder') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-select
                                    :items='documentFolders'
                                    :item-text="item => $t('message.docFolders.' + item.text)"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="Document__folder"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <template v-for="category in Document__categories">
                                <div class="d-flex flex-row py-2 justify-space-between align-center" :key="'switch_' + category">
                                    <span class="black--text text--darken-1">{{ $t('message.docTypes.' + category) }}</span>
                                    <v-switch
                                            :false-value="0"
                                            :input-value="category in Document__categories_types ? Document__categories_types[category] : 0"
                                            :label="category in Document__categories_types ? (Document__categories_types[category] === 1 ? $t('message.standard') : $t('message.nonStandard')) : $t('message.nonStandard')"
                                            :true-value="1"
                                            class="mt-0"
                                            dense
                                            hide-details="auto"
                                            @change="updateTypeValue(category, $event)"
                                    />
                                </div>
                            </template>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.linked') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-checkbox
                                    :true-value="1"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="Document__linked"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="Document__linked == 1">
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.linkedUrl') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Document__linked_url"
                                    :rules="Document__linked == 1 ? [...validationRules.required, ...validationRules.url] : [...validationRules.url]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Document__linked_url = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.autogenerated') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-checkbox
                                    :true-value="1"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="Document__autogenerated"
                            />
                        </v-flex>
                    </v-layout>
<!--                    <v-layout row v-if="Document__autogenerated == 1">-->
<!--                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.autogenerated') }}</v-flex>-->
<!--                        <v-flex xs6 lg8 md6 py-1>-->
<!--                            <v-text-field></v-text-field>-->
<!--                        </v-flex>-->
<!--                    </v-layout>-->
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addStandardShippingDocument()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveStandardShippingDocument()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions, mapGetters} from "vuex";
import {isObject, log} from "Helpers/helpers";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddStandardShippingDocument",
    props: ['standardShippingDocument','standardShippingDocumentId','updateMode','dialog','pageKey'],
    components: { HelpPopUpV2 },
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            selectedAllOptions: {},
            selectedOptions: {},
            selectedSomeOptions: {},
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('document', {
            Document__id: 'Document.id',
            Document__doctype_id: 'Document.doctype_id',
            Document__categories_types: 'Document.categories_types',
            Document__customeraccess: 'Document.customeraccess',
            Document__fieldname: 'Document.fieldname',
            Document__folder: 'Document.folder',
            Document__title: 'Document.title',
            Document__standard: 'Document.standard',
            Document__fileName: 'Document.fileName',
            Document__categories: 'Document.categories',
            Document__linked: 'Document.linked',
            Document__linked_url: 'Document.linked_url',
            Document__autogenerated: 'Document.autogenerated'
        }, 'statePrefix'),
        ...mapGetters('document', {
            documentCategories: 'documentCategories',
            documentFolders: 'documentFolders'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        ...mapActions('document', {
            createStandardShippingDocument: 'createStandardShippingDocument',
            getStandardShippingDocumentToUpdateById: 'getStandardShippingDocumentToUpdateById',
            resetStandardShippingDocumentToUpdate: 'resetStandardShippingDocumentToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateStandardShippingDocument: 'updateStandardShippingDocument'
        }),
        addStandardShippingDocument() {
            if(this.$refs.standardShippingDocumentForm.validate()){
                this.loading.add = true
                this.createStandardShippingDocument()
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.standardShippingDocumentAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.standardShippingDocumentNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.standardShippingDocumentNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.standardShippingDocumentNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.standardShippingDocumentNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        removeOption(item) {
            const index = this.Document__categories.indexOf(item)
            if (index >= 0) this.Document__categories.splice(index, 1)
            delete this.Document__categories_types[item]
        },
        saveStandardShippingDocument() {
            if(this.$refs.standardShippingDocumentForm.validate()){
                this.loading.save = true
                this.updateStandardShippingDocument()
                    .then((response) => {
                        if(response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.standardShippingDocumentUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.standardShippingDocumentNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.standardShippingDocumentNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.standardShippingDocumentNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.standardShippingDocumentNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        updateCategoriesTypes(value) {
            log(value)
        },
        updateTypeValue(category, type) {
            //check if categories_types is empty object
            const value = type ? 1 : 0
            if(Object.keys(this.Document__categories_types).length === 0){
                this.Document__categories.forEach(c => {
                    this.Document__categories_types[c] = 0
                })
            }
            this.Document__categories_types[category] = value
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getStandardShippingDocumentToUpdateById(this.standardShippingDocumentId)
                        .then( () => {
                            this.loading.get = false
                        })
                        .catch( () => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetStandardShippingDocumentToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
}
</script>

<style scoped>

</style>